import { AnimatePresence, Variants, m } from 'framer-motion'
import { useCheckout } from 'lib-shopify'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import GlyphAnimationLoader from '@components/glyphAnimationLoader'

import { useLenis } from '@lib/lenisContext'

const CartPanel = () => {
  const router = useRouter()
  const lenis = useLenis()
  const { cart } = useCheckout()

  useEffect(() => {
    router.events.on('routeChangeStart', cart.close)
    return () => {
      router.events.off('routeChangeStart', cart.close)
    }
  }, [])

  useEffect(() => {
    if (!lenis) return
    if (cart.open) lenis.stop()
    else lenis.start()
  }, [cart.open])

  return (
    <AnimatePresence>
      {cart.open ? (
        <m.div initial="initial" animate="animate" exit="exit">
          <m.div
            variants={panelVariants}
            transition={{ type: 'ease', ease: [0, 0, 0, 1] }}
            className="fixed z-[999] top-12 right-0 bottom-0 w-full flex flex-col sm:w-2/5 sm:min-w-[36rem] sm:border-l-2 border-black bg-background text-foreground [--translate-x-from:0%] [--translate-x-to:0%] [--translate-y-from:8%] [--translate-y-to:0%] md:[--translate-x-from:20%] md:[--translate-x-to:0%] md:[--translate-y-from:0%] md:[--translate-y-to:0%]"
          >
            {cart.value ? (
              <>
                <LineItems />
                <BuyNow />
              </>
            ) : (
              <div className="relative w-full h-full flex justify-center items-center">
                <GlyphAnimationLoader />
              </div>
            )}
          </m.div>
          <m.div
            variants={overlayVariants}
            className="fixed inset-0 z-[50] bg-black cursor-w-resize"
            onClick={cart.toggle}
          />
        </m.div>
      ) : null}
    </AnimatePresence>
  )
}

const BuyNow = () => {
  const { cart } = useCheckout()
  const cartIsEmpty = cart.value.lineItems.length === 0
  const totalPrice = cart.value.subtotalPriceV2
  const label = cartIsEmpty ? '' : 'Go to checkout'

  async function handleGoToCheckout() {
    if (!cartIsEmpty) window.open(cart.value.webUrl)
  }

  return (
    <button
      className="w-full bg-foreground text-background p-4 text-xl flex justify-between"
      onClick={handleGoToCheckout}
    >
      <span>
        {totalPrice.amount} {totalPrice.currencyCode}
      </span>
      <span>{label}</span>
    </button>
  )
}

const PHRASES = [
  'Cart is a little empty innit',
  "What'd you expect to find here? Go spend that monay!",
  "That 0.0 is a little sad, don't you think?",
]

export const LineItems = () => {
  const idx = Math.floor(Math.random() * PHRASES.length)
  const randomInitialPhrase = PHRASES[idx]

  const [phrase] = useState(randomInitialPhrase)
  const { cart } = useCheckout()

  return (
    <div className="w-full grow overflow-y-auto" data-lenis-prevent>
      {cart.value.lineItems.length > 0 ? (
        cart.value.lineItems.map((li, i) => {
          return <SingleLineItem key={i} data={li} />
        })
      ) : (
        <div className="relative w-full h-full flex justify-center items-center">
          {/* <div className="relative w-8 h-8">
            <GlyphAnimationLoader />
          </div> */}
          <span className="ml-6">{phrase}</span>
        </div>
      )}
    </div>
  )
}

const SingleLineItem = ({ data }) => {
  const { id, title, quantity, variant } = data
  const { selectedOptions, image, price } = variant
  const handle = variant.product.handle

  return (
    <m.div className="mx-2 py-2 border-b-px">
      {/* TO-DO: redirect to correct variant */}
      <Link
        href={`/shop/products/${handle}`}
        className="flex justify-between items-baseline text-xl lg:text-2xl"
      >
        <div>
          <div className="block whitespace-nowrap underline underline-offset-2">
            {title}
          </div>
          {selectedOptions.map(opt => (
            <div key={opt.name} className="text-xl">
              <span className="inline whitespace-nowrap">{opt.name}</span>:{' '}
              <span className="inline whitespace-nowrap">{opt.value}</span>
            </div>
          ))}
        </div>
        <span className="whitespace-nowrap">
          {price.amount} {price.currencyCode}
        </span>
      </Link>
      <div className="flex items-center justify-between py-2">
        <div className="relative w-36 sm:w-48 aspect-square">
          <Image
            src={image.src}
            alt={image.alt ?? ''}
            // width={image.width}
            // height={image.height}
            fill
          />
        </div>
        <QuantitySelector lineItemId={id} quantity={quantity} />
      </div>
    </m.div>
  )
}

const QuantitySelector = ({
  lineItemId,
  quantity,
}: {
  lineItemId: string
  quantity: number
}) => {
  const { /* loading, */ checkout } = useCheckout()

  async function decreaseQuantity() {
    const newQuantity = quantity - 1
    await checkout.updateQuantity(lineItemId, newQuantity)
  }

  async function increaseQuantity() {
    const newQuantity = quantity + 1
    await checkout.updateQuantity(lineItemId, newQuantity)
  }

  return (
    <div className="flex text-xl">
      <button
        className="w-8 h-8 rounded-bl-lg border-px border-foreground flex justify-center"
        onClick={decreaseQuantity}
      >
        <div className="block w-4 h-4">-</div>
      </button>
      <span className="w-12 h-8 text-center">{quantity}</span>
      <button
        className="w-8 h-8 rounded-tr-lg border-px border-foreground flex justify-center"
        onClick={increaseQuantity}
      >
        <div className="block w-4 h-4">+</div>
      </button>
    </div>
  )
}

const panelVariants: Variants = {
  initial: {
    opacity: 0.6,
    x: 'var(--translate-x-from)',
    y: 'var(--translate-y-from)',
  },
  animate: {
    opacity: 1,
    x: 'var(--translate-x-to)',
    y: 'var(--translate-y-to)',
  },
  exit: {
    opacity: 0,
    x: 'var(--translate-x-from)',
    y: 'var(--translate-y-from)',
  },
}

const overlayVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 0.65 },
  exit: { opacity: 0 },
}

export default CartPanel
